<template>
  <div>
    <div
      class="container-fluid d-flex flex-column justify-content-between subscribe_layout align-items-center"
    >
      <div
        style="
          text-align: end;
          color: #d1d0cf;
          align-items: end !important;
          margin: 10px;
          width: 100%;
        "
      >
        <div></div>
        <div v-if="$i18n.locale === 'en'" @click="changeLang('ar')">عربي</div>
        <div v-if="$i18n.locale === 'ar'" @click="changeLang('en')">
          English
        </div>
      </div>
      <div class="text-center">
        <div style="font-size: 12px">{{ $t('login.header') }}</div>
      </div>
      <img src="@/assets/images/logo.png" class="test" alt="" />
      <div class="text-center align-items-center">
        <router-view :key="$route.path"></router-view>
      </div>
      <div>
        <div
          style="text-anchor: middle; font-size: 0.6rem; color: #fff"
          :class="$i18n.locale === 'ar' ? 'dir-rtl' : 'dir-ltr '"
        >
          {{ $t('login.subTerms') }}
        </div>

        <ul
          v-if="$i18n.locale === 'ar'"
          style="
            font-size: 0.6rem;
            color: #fff;
            margin: 10px;
            direction: rtl;
            text-align: start;
          "
        >
          <li>أهلاً بكِ في بوابة HighFit. تقدم هذه الخدمة للمستخدم</li>
          <li>
            مجانًا لمدة 24 ساعة بعد ذلك، سيتم تلقائيًا تحصيل 2.10 درهما يوميًا.
          </li>
          <li>
            لا يوجد التزام، يمكنك إلغاء الاشتراك في أي وقت عن طريق إرسال C HIF
            إلى 1111
          </li>
          <li>للمساعدة، يرجى التواصل مع support@mt2morrow.com</li>
          <li>التجربة المجانية متاحة لمشتركي اتصالات الجدد فقط</li>
          <li>استمتع بتجربتك المجانية لمدة 24 ساعة</li>
          <li>
            يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية
            وأن لديك اتصال إنترنت آمن للوصول السريع إلى المحتوى
          </li>
          <li>
            تقنيات الحظر ولديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.
          </li>
          <li>
            لكي تستطيع استخدام هذه الخدمة يجب عليك أن تكون فوق الـ18 عامًا أو
            يكون لديك إذن من عائلتك أو من الشخص المفوض بدفع فاتورة هاتفك.
            الأسعار تشمل ضريبة القيمة المضافة بنسبة 5%
          </li>
          <li>
            للحصول على كامل الشروط والأحكام
            <span
              style="text-decoration: underline; color: #e03339"
              v-b-modal.my-modal
              >{{ $t('login.clickHere') }}</span
            >
          </li>
        </ul>
        <ul
          v-else
          style="
            font-size: 0.6rem;
            color: #fff;
            margin: 10px;
            text-align: start;
          "
        >
          <li>Welcome to the HighFit portal. This service offers the user</li>
          <li>
            Free for 24 hours then, you will be charged AED 2.10/day
            automatically.
          </li>
          <li>
            No commitment, you can cancel any time by sending C HIF to 1111.
          </li>
          <li>For support, please contact us at support@mt2morow.com</li>
          <li>Free trial applicable only for first time subscriber.</li>
          <li>Enjoy your Free trial for 24 hours.</li>
          <li>
            Please make sure that your browser is not using any 3rd-party.
          </li>
          <li>
            Blocking technologies and you have a healthy internet connection for
            swift access to the content.
          </li>
          <li>
            To make use of this service, you must be 18 or more unless you have
            received permission from your parents or the person who is
            authorized to pay your bill. Prices are subject to 5% VAT
          </li>
          <li>
            For full terms and condition
            <span
              style="text-decoration: underline; color: #e03339"
              v-b-modal.my-modal
              >{{ $t('login.clickHere') }}</span
            >
          </li>
        </ul>
      </div>
      <!-- :title="$t('login.terms')" -->
      <b-modal
        id="my-modal"
        title="Terms And Conditions"
        hide-footer
        header-class="text-end"
        class="modal"
      >
        <!-- v-if="$i18n.locale === 'en'" -->
        <div style="font-size: 14px">
          <strong>Terms and Conditions ("Terms")</strong>
          <br />
          Last updated: 1/8/2025
          <br />
          offices at Yarmouk District 608, Street 2, Building 6, 5th Floor
          <br />
          <strong>1. HighFit Content Service</strong>
          <br />
          These terms (including, but not limited to, information on how to use
          the service) apply to the service as published in relation to the
          service. By subscribing to HighFit you acknowledge that you have read,
          understood, and agree to be bound by these terms. If you do not accept
          these terms, you must not subscribe/register or use HighFit.
          <br />
          <strong>2. Service Operation</strong>
          <br />

          HighFit works as follows: After following the instructions and
          entering your mobile number on the website, you will receive a PIN
          code. Once you enter the code you are subscribed to the service. You
          will receive a welcome message containing all relevant information
          about the service (price, number of messages, etc.) and a service
          description. As part of your subscription, depending on the service
          you join, you will also receive SMS messages containing mobile content
          ("subscription messages").
          <br />
          <strong>3. Service Status and Costs</strong>
          <br />

          The subscription starts at AED 2.10 PRICE per Day and will
          automatically renew daily. There is no commitment. You can cancel at
          any time by sending C HIF to 1111. For support, please contact
          support@mt2morrow.com
          <br />
          <strong>4. Subscriber Definition</strong>
          <br />

          A subscriber is a person (you or any individual using your mobile
          phone) who follows the registration steps directed on the website. By
          subscribing to our services, you agree to receive one subscription
          message per week containing any mobile content related to the service.
          The content of these messages is for personal use only.
          <br />
          <strong>5. Usage Restrictions</strong>
          <br />

          To use the service, you must be a resident of United Arab Emirates, at
          least 18 years old, or an immediate family member. If you are under
          18, you must obtain explicit permission from an adult or the mobile
          phone owner. The SMS subscription mechanism is only open to consumers
          with a mobile phone compatible with SMS messaging connected to a
          service provider that allows texting to the phone number we advertise
          for the service. We recommend checking with your individual service
          provider in this regard.
          <br />

          <strong>6. Subscription Validation</strong>
          <br />

          We reserve the right to validate the subscription or subscriber and
          disqualify any subscription or subscriber that tampers with the
          service or does not use the service according to these terms.
          <br />

          <strong>7. Unsubscription</strong>
          <br />

          To voluntarily unsubscribe or withdraw from our services, the end user
          must send the word C HIF to 1111. We reserve the right to suspend or
          prevent anyone from subscribing to the service.
          <br />

          <strong>8. Service Interruption</strong>
          <br />

          If the service is unable to operate as planned for any reason
          (including, but not limited to, computer viruses, errors, tampering,
          unauthorized intervention, fraud, technical failures, or any other
          causes) that could compromise the integrity or proper functioning of
          the service, we reserve the right to cancel, terminate, modify, or
          suspend the service.
          <br />

          <strong>9. Service Suspension or Termination</strong>
          <br />

          If, for any reason, our services are unable to operate as planned
          (including, but not limited to, technical failures, unauthorized
          interference, fraud, or any other causes beyond our control that
          compromise the management, security, fairness, or proper conduct of
          the service), we have the right, at our sole discretion, to exclude
          any individual who tampers with the service, cancel, terminate,
          modify, or suspend the service.
          <br />

          <strong>10. Liability</strong>
          <br />

          We are not responsible for any failure to comply with these terms. If
          any part or provision of these terms is found to be invalid,
          unenforceable, or illegal for any reason, that part or provision will
          be severed, and the remaining provisions will remain in full force and
          effect.
          <br />

          <strong>11. Entire Agreement</strong>
          <br />

          These terms and conditions constitute the entire agreement between you
          and the owner of our services. By subscribing to the service, you
          agree to all terms and conditions and authorize Etisalat to share your
          mobile number with the service providers.
        </div>
        <!-- <div style="font-size: 14px; direction: rtl; text-align: start" v-else>
          <strong>الشروط والأحكام ("الشروط")</strong>
          <br />
          آخر تحديث: 1/8/2025
          <br />
          حي اليرموك 608 ، شارع 2 ، مبنى 6 ، الطابق 5
          <br />
          <strong>1. خدمة محتوى HighFit </strong>
          <br />
          تنطبق هذه الشروط (بما في ذلك، على سبيل المثال لا الحصر، معلومات حول
          كيفية استخدام الخدمة) على النحو التالي: تم نشرها فيما يتعلق بالخدمة.
          من خلال الاشتراك في HighFit ، اقرأ هذه الشروط وافهمها ووافق على
          الالتزام بها. إذا كنت لا توافق على هذه الشروط، فيجب عليك لا تشترك /
          تسجل أو تستخدم HighFit.
          <br />
          <strong>2. عملية الخدمة</strong>
          <br />

          يعمل HighFit على النحو التالي: بعد اتباع التعليمات وإدخال رقم هاتفك
          المحمول على موقع الكتروني ، ستتلقى رمز PIN. بمجرد إدخال الرمز ، تكون
          مشتركا في الخدمة. أنت سوف تتلقى رسالة ترحيب تحتوي على جميع المعلومات
          ذات الصلة بالخدمة (السعر ، عدد الرسائل ، إلخ.) ووصف الخدمة. كجزء من
          اشتراكك، اعتمادا على الخدمة التي تقوم بها انضم ، ستتلقى أيضا رسائل SMS
          تحتوي على محتوى الهاتف المحمول ("رسائل الاشتراك").
          <br />
          <strong>3. حالة الخدمة وتكاليفها</strong>
          <br />

          يبدأ الاشتراك من 2.10 درهم إماراتي في اليوم وسيتم تجديده تلقائيا
          يوميا. لا يوجد التزام. يمكنك الإلغاء في أي وقت عن طريق إرسال C HIF إلى
          1111. للحصول على الدعم ، من فضلك اتصل support@mt2morrow.com
          <br />
          <strong>4. تعريف المشترك</strong>
          <br />

          المشترك هو الشخص (أنت أو أي فرد يستخدم هاتفك المحمول) يتابع التسجيل
          الخطوات الموجهة على الموقع. من خلال الاشتراك في خدماتنا ، فإنك توافق
          على الحصول على اشتراك واحد رسالة أسبوعيا تحتوي على أي محتوى جوال متعلق
          بالخدمة. محتوى هذه الرسائل للاستخدام الشخصي فقط.
          <br />
          <strong>5. قيود الاستخدام</strong>
          <br />

          لاستخدام الخدمة، يجب أن تكون مقيما في دولة الإمارات العربية المتحدة، و
          لا يقل عمرك عن 18 عاما، أو أحد أفراد الأسرة المباشرين. إذا كان عمرك
          أقل من 18 عاما ، فيجب عليك الحصول على إذن صريح من شخص بالغ أو مالك
          الهاتف المحمول. آلية الاشتراك في الرسائل النصية القصيرة مفتوحة فقط
          للمستخدمين الذين لديهم هاتف محمول متوافق مع الرسائل النصية القصيرة
          المتصلة بمزود خدمة يسمح بإرسال الرسائل النصية إلى الهاتف الرقم الذي
          نعلن عنه للخدمة. نوصي بمراجعة مزود الخدمة الفردي الخاص بك في هذا الصدد
          <br />

          <strong>6. التحقق من صحة الاشتراك</strong>
          <br />

          نحتفظ بالحق في التحقق من صحة الاشتراك واستبعاد أي اشتراك يعبث بالخدمة
          أو لا يستخدم الخدمة وفقا لهذه الشروط.
          <br />

          <strong>7. إلغاء الاشتراك</strong>
          <br />

          لإلغاء الاشتراك أو الانسحاب من خدماتنا طوعا، يجب على المستخدم النهائي
          إرسال كلمة C HIF إلى 1111. نحتفظ بالحق في تعليق أو منع أي شخص من
          الاشتراك في الخدمة.
          <br />

          <strong>8. انقطاع الخدمة</strong>
          <br />

          إذا كانت الخدمة غير قادرة على العمل كما هو مخطط لها لأي سبب من الأسباب
          (بما في ذلك، على سبيل المثال لا الحصر، الكمبيوتر الفيروسات أو الأخطاء
          أو العبث أو التدخل غير المصرح به أو الاحتيال أو الأعطال الفنية أو أي
          أسباب أخرى) التي يمكن أن تعرض سلامة الخدمة أو أدائها السليم للخطر،
          نحتفظ بالحق في الإلغاء، إنهاء الخدمة أو تعديلها أو تعليقها.
          <br />

          <strong>9. تعليق الخدمة أو إنهائها</strong>
          <br />

          إذا كانت خدماتنا، لأي سبب من الأسباب، غير قادرة على العمل كما هو مخطط
          لها (بما في ذلك، على سبيل المثال لا الحصر، التقنية الإخفاقات أو التدخل
          غير المصرح به أو الاحتيال أو أي أسباب أخرى خارجة عن سيطرتنا تعرض للخطر
          الإدارة أو الأمن أو الإنصاف أو السلوك السليم للخدمة) ، لدينا الحق
          باستبعاد أي فرد يعبث بالخدمة أو إلغاء أو إنهاء أو تعديل أو تعليق
          الخدمة
          <br />

          <strong>10. المسؤولية</strong>
          <br />

          نحن لسنا مسؤولين عن أي فشل في الامتثال لهذه الشروط. إذا كان أي جزء أو
          حكم من هذه تبين أن الشروط غير صالحة أو غير قابلة للتنفيذ أو غير
          قانونية لأي سبب من الأسباب، سيكون هذا الجزء أو الحكم متوقف ، وستظل
          الأحكام المتبقية سارية المفعول والتأثير الكامل.
          <br />

          <strong>11. الاتفاقية الكاملة</strong>
          <br />

          تشكل هذه الشروط والأحكام الاتفاقية الكاملة بينك وبين مالك الخدمة. من
          خلال الاشتراك في الخدمة، فإنك توافق على جميع الشروط والأحكام وتفوض
          اتصالات لمشاركة رقم هاتفك المحمول مع مزودي الخدمة.
        </div> -->
      </b-modal>
    </div>
  </div>
</template>

<style lang="scss">
.modal {
  max-height: 600px;
  height: 100%;
  overflow-y: scroll;
  color: #212529;
  padding: 0 0 20px 0;
}

.subscribe_layout {
  background-image: url('../assets/images/subscribe/subscribe_desk_bg.png');
  min-height: 100vh;
  background-position: center;
  background-size: 100% 100%;

  @media (max-width: 768px) {
    background-image: url('../assets/images/subscribe/bg_mobile.png');
  }
}

.dir-ltr {
  direction: ltr;
  text-align: left;
}

.dir-rtl {
  direction: rtl;
  text-align: right;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 500px;
    font-size: 40.621px;
    line-height: 49px;
  }

  @media (max-width: 600px) {
    width: 100%;
    font-size: 22.621px;
    line-height: 22px;
  }

  .text_info {
    color: white;

    @media (min-width: 600px) {
      font-size: 38px;
    }

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  .input,
  .butn {
    width: inherit;
  }

  .game_input,
  .game_btn {
    width: 100%;
    border: 0.2px solid #ffffff;
    background: linear-gradient(90deg, #0e2229 0%, #0e2229 100%);
    opacity: 0.7;
    border-radius: 40px;
    font-style: normal;
    font-weight: 400;
    color: white;

    @media (min-width: 600px) {
      height: 81px;
    }

    @media (max-width: 600px) {
      height: 55px;
    }
  }
  .game_btn {
    width: 50%;
    font-size: 16px;
  }
  .activeBtn {
    background: #defe71 !important;
    color: black;
  }

  .game_input {
    @media (min-width: 600px) {
      padding-left: 60px;
    }

    @media (max-width: 600px) {
      padding-left: 20px;
    }
  }

  .game_btn {
    outline: none;
    padding: 0px;
  }

  #otp {
    display: flex;
    justify-content: space-around;
    width: inherit;
    margin-bottom: 2rem;

    input {
      height: 70px;
      width: 70px;
      text-align: center;
      background: linear-gradient(90deg, #161621 0%, #0e2229 100%);
      opacity: 0.7;
      font-size: 20px;
      outline: none;
      border-radius: 8px;
      border: 0.2px solid #ffffff;
      color: white;

      @media (max-width: 600px) {
        height: 60px;
        width: 20%;
      }
    }
  }

  .desc {
    width: 80%;
    text-align: center;
    line-height: 1.6;

    @media (min-width: 600px) {
      font-size: 16px;
    }

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
}

::placeholder {
  color: white;
}
</style>
<script>
import ApiService from '../services/api'

export default {
  name: 'SubscribeLayout',
  methods: {
    changeLang (lang) {
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      ApiService.changeLAng()
      this.$http.defaults.headers.common['lang'] = localStorage.getItem('lang')
    }
    // goToTermsAndConditions () {
    //   console.log('test')
    //   this.$router.push('/terms-and-conditions')
    // }
  }
}
</script>
