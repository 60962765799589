<template>
  <div class="subscribe_layout">
    <div class="content-container">
      <!-- <div
        class="go-back-btn"
        :style="{ direction: lang === 'ar' ? 'rtl' : 'ltr' }"
      >
        <i class="fas fa-caret-left" v-if="lang === 'en'"></i>
        <i class="fas fa-caret-right" v-else></i>
        <p>back</p>
      </div> -->
      <div class="terms-content">
        <strong>Privacy Policy</strong>
        <br />

        <p style="margin-bottom: 10px">
          At Nahla Zakia, HighFit, we value your privacy and are committed to
          protecting your personal information. This Privacy Policy outlines how
          we collect, use, and share your information when you subscribe to our
          service. By subscribing to our service, you consent to the practices
          described in this policy. Please read this policy carefully to
          understand how we handle your information.
        </p>

        <strong>1. Information We Collect</strong>
        <ul style="line-height: 20px">
          <li>
            When you use our service, we may collect the following types of
            information:
          </li>
          <li>
            A.Personal Information: Subscription Data: When you subscribe to our
            service, we collect your mobile phone number and any other necessary
            billing details. These details help us process your subscription and
            maintain accurate records. Age Verification: To comply with age
            restrictions, we may collect information to ensure that you are over
            18 years of age or have permission from a parent or guardian if you
            are under 18.
          </li>
          <li>
            B.Technical Information: Device and Network Information: When you
            access our portal,
          </li>
          <li>
            we may collect details about your device, such as your IP address,
            browser type, and
          </li>
          <li>
            mobile network information. Usage Data: We track how you use our
            service, including
          </li>
          <li>
            browsing activities, page interactions, and the content you access.
          </li>
        </ul>

        <strong>2. How We Use Your Information</strong>
        <p>We use the collected information for the following purposes:</p>
        <ul style="line-height: 20px">
          <li>
            Service Provision and Maintenance: To process your subscription,
            including automatic renewal unless you choose to unsubscribe. To
            send you and renewal alerts via SMS.
          </li>
          <li>
            Communication: To send you important service-related updates, such
            as subscription, renewals. To respond to any feedback or inquiries
            you submit via support@mt2morrow.com.
          </li>
          <li>
            Improvement of Services: To analyze how users interact with our
            service so that we can improve the platform, including the user
            interface, content offerings, and overall performance.
          </li>
          <li>
            Compliance with Legal Obligations: We may use your personal
            information to comply with applicable laws, regulations, and legal
            processes.
          </li>
        </ul>

        <strong>3. Cookies and Tracking Technologies</strong>
        <ul style="line-height: 20px">
          <li>
            We may use cookies and similar tracking technologies to enhance your
            experience on our portal. Cookies are small data files stored on
            your device that allow us to:
          </li>
          <li>
            Remember your preferences, such as language settings (the portal is
            in English). Track usage patterns and improve service performance.
            You can manage your cookie preferences through your browser
            settings, but disabling cookies may affect your ability to use some
            features of the service.
          </li>
        </ul>

        <strong>4. Data Sharing and Disclosure</strong>
        <ul style="line-height: 20px">
          <li>
            We do not sell or rent your personal information to third parties.
            However, we may share your information in the following scenarios:
          </li>
          <li>
            Legal Compliance: We may disclose your information if required to do
            so by law or in response to valid legal requests from government
            authorities.
          </li>
          <li>
            Data Security We implement appropriate technical and organizational
            measures to protect your personal information from unauthorized
            access, disclosure, alteration, or destruction. However, no method
            of data transmission or storage is completely secure, and we cannot
            guarantee the absolute security of your information.
          </li>
        </ul>

        <strong>5. Retention of Your Information</strong>
        <ul style="line-height: 20px">
          <li>
            We will retain your personal information for as long as necessary to
            fulfill the purposes outlined in this policy. This may include
            retaining your data to comply with legal obligations, resolve
            disputes, and enforce our agreements.
          </li>
        </ul>

        <strong>6. Children’s Privacy</strong>
        <ul style="line-height: 20px">
          <li>
            Our service is intended for users who are at least 18 years old or
            who have obtained parental consent. We do not knowingly collect
            personal information from children under 18. If we become aware that
            we have inadvertently collected personal data from a child without
            consent, we will take steps to delete that information.
          </li>
        </ul>

        <strong>7. Data Usage and Charges</strong>
        <ul style="line-height: 20px">
          <li>
            Please note that standard data charges may apply when browsing or
            downloading content from our portal if you are not using a data
            bundle. It is your responsibility to manage your data usage
            according to your mobile plan.
          </li>
        </ul>

        <strong>8. International Data Transfers</strong>
        <ul style="line-height: 20px">
          <li>
            We operate globally, and your information may be transferred to and
            processed in countries outside your own. We ensure that any data
            transfers comply with applicable privacy laws and that your data is
            protected by adequate safeguards.
          </li>
        </ul>

        <strong>9. Changes to This Privacy Policy</strong>
        <ul style="line-height: 20px">
          <li>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or relevant legal requirements. We will
            notify you of any significant changes by posting a notice on our
            portal or sending an SMS alert. Your continued use of the service
            after such changes constitutes acceptance of the revised policy.
          </li>
        </ul>

        <strong>10. Contact Us</strong>
        <ul style="line-height: 20px">
          <li>
            If you have any questions, concerns, or feedback regarding this
            Privacy Policy or our data handling practices, please contact us at
            support@mt2morrow.com.
          </li>
          <li>
            By using our service, you acknowledge that you have read and
            understood this Privacy Policy and agree to the collection, use, and
            disclosure of your personal information as described herein.
          </li>
        </ul>
      </div>
      <!-- <div
        style="direction: rtl; text-align: start"
        class="terms-content"
        v-else
      >
        <strong>سياسة الخصوصية</strong>
        <br />

        <p style="margin-bottom: 10px">
          في النحلة الذكية، هاي فيت، نقدر خصوصيتك ونلتزم بحمايتها معلوماتك
          الشخصية. توضح سياسة الخصوصية هذه كيفية جمعنا واستخدامنا ومشاركتها
          الخاصة بك عند الاشتراك في خدمتنا. من خلال الاشتراك في خدمتنا، فإنك
          توافق على الممارسات الموضحة في هذه السياسة. يرجى قراءة هذه السياسة
          بعناية لفهم كيفية التعامل مع معلوماتك.
        </p>

        <strong>1. المعلومات التي نجمعها</strong>
        <ul style="line-height: 20px">
          <li>
            عند استخدامك لخدمتنا، قد نقوم بجمع الأنواع التالية من المعلومات:
          </li>
          <li>
            . المعلومات الشخصية: بيانات الاشتراك: عند الاشتراك في خدمتنا، نقوم
            بجمع رقم هاتفك المحمول وأي تفاصيل فواتير ضرورية أخرى. تساعدنا هذه
            التفاصيل في معالجة اشتراكك والاحتفاظ بسجلات دقيقة. التحقق من العمر:
            للامتثال للقيود العمرية، قد نجمع معلومات للتأكد من أن عمرك يزيد عن
            18 عاما أو أن يكون لديك إذن من أحد الوالدين أو الوصي إذا كان عمرك
            أقل من 18 عاما.
          </li>
          <li>
            ب. المعلومات الفنية: معلومات الجهاز والشبكة: عند الوصول إلى بوابتنا
            ،
          </li>
          <li>قد نجمع تفاصيل حول جهازك، مثل عنوان IP الخاص بك ونوع المتصفح</li>
          <li>
            معلومات شبكة الهاتف المحمول. بيانات الاستخدام: نتتبع كيفية استخدامك
            لخدمتنا، بما في ذلك أنشطة التصفح وتفاعلات الصفحة والمحتوى الذي تصل
            إليه.
          </li>
          <li>أنشطة التصفح، وتفاعلات الصفحة، والمحتوى الذي تصل إليه.</li>
        </ul>

        <strong>2. كيف نستخدم معلوماتك</strong>
        <p>نستخدم المعلومات التي تم جمعها للأغراض التالية:</p>
        <ul style="line-height: 20px">
          <li>
            توفير الخدمة وصيانتها: لمعالجة اشتراكك، بما في ذلك التجديد التلقائي
            ما لم تختر إلغاء الاشتراك. لإرسال تنبيهات التجديد إليك عبر الرسائل
            القصيرة.
          </li>
          <li>
            التواصل: لإرسال تحديثات مهمة متعلقة بالخدمة إليك ، مثل الاشتراك
            والتجديدات. للرد على أي ملاحظات أو استفسارات ترسلها عبر
            support@mt2morrow.com.
          </li>
          <li>
            تحسين الخدمات: لتحليل كيفية تفاعل المستخدمين مع خدمتنا حتى نتمكن من
            تحسين النظام الأساسي ، بما في ذلك واجهة المستخدم وعروض المحتوى
            والأداء العام
          </li>
          <li>
            الامتثال للالتزامات القانونية: قد نستخدم معلوماتك الشخصية للامتثال
            للقوانين واللوائح والإجراءات القانونية المعمول بها.
          </li>
        </ul>

        <strong>3. ملفات تعريف الارتباط وتقنيات التتبع </strong>
        <ul style="line-height: 20px">
          <li>
            قد نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة لتحسين تجربتك
            على بوابتنا. ملفات تعريف الارتباط هي ملفات بيانات صغيرة مخزنة على
            جهازك تسمح لنا بما يلي:
          </li>
          <li>
            تذكر تفضيلاتك ، مثل إعدادات اللغة (البوابة باللغة الإنجليزية). تتبع
            أنماط الاستخدام وتحسين أداء الخدمة. يمكنك إدارة تفضيلات ملفات تعريف
            الارتباط الخاصة بك من خلال إعدادات المتصفح الخاص بك ، ولكن قد يؤثر
            تعطيل ملفات تعريف الارتباط على قدرتك على استخدام بعض ميزات الخدمة.
          </li>
        </ul>

        <strong>4. مشاركة البيانات والإفصاح عنها</strong>
        <ul style="line-height: 20px">
          <li>
            نحن لا نبيع أو نؤجر معلوماتك الشخصية لأطراف ثالثة. ومع ذلك، قد نشارك
            معلوماتك في الحالات التالية:
          </li>
          <li>
            الامتثال القانوني: قد نكشف عن معلوماتك إذا طلب منا القيام بذلك بموجب
            القانون أو استجابة لطلبات قانونية صالحة من السلطات الحكومية.
          </li>
          <li>
            أمن البيانات نقوم بتنفيذ التدابير الفنية والتنظيمية المناسبة لحماية
            معلوماتك الشخصية من الوصول غير المصرح به أو الكشف أو التغيير أو
            التدمير. ومع ذلك، لا توجد طريقة لنقل البيانات أو تخزينها آمنة تماما،
            ولا يمكننا ضمان الأمان المطلق لمعلوماتك.
          </li>
        </ul>

        <strong>5. الاحتفاظ بمعلوماتك</strong>
        <ul style="line-height: 20px">
          <li>
            سنحتفظ بمعلوماتك الشخصية طالما كان ذلك ضروريا لتحقيق الأغراض الموضحة
            في هذه السياسة. قد يشمل ذلك الاحتفاظ ببياناتك للامتثال للالتزامات
            القانونية وحل النزاعات وإنفاذ اتفاقياتنا.
          </li>
        </ul>

        <strong>6. خصوصية الأطفال</strong>
        <ul style="line-height: 20px">
          <li>
            خدمتنا مخصصة للمستخدمين الذين لا تقل أعمارهم عن 18 عاما أو الذين
            حصلوا على موافقة الوالدين. نحن لا نجمع معلومات شخصية عن قصد من
            الأطفال دون سن 18 عاما. إذا علمنا أننا جمعنا بيانات شخصية عن غير قصد
            من طفل دون موافقة، فسنتخذ خطوات لحذف هذه المعلومات.
          </li>
        </ul>

        <strong>7. استخدام البيانات والرسوم</strong>
        <ul style="line-height: 20px">
          <li>
            يرجى ملاحظة أنه قد يتم تطبيق رسوم البيانات القياسية عند تصفح أو
            تنزيل المحتوى من بوابتنا إذا كنت لا تستخدم حزمة بيانات. تقع على
            عاتقك مسؤولية إدارة استخدام البيانات وفقا لخطة هاتفك المحمول.
          </li>
        </ul>

        <strong>8. عمليات نقل البيانات الدولية</strong>
        <ul style="line-height: 20px">
          <li>
            نحن نعمل على مستوى العالم، وقد يتم نقل معلوماتك ومعالجتها في بلدان
            خارج بلدك. نحن نضمن أن أي عمليات نقل بيانات تتوافق مع قوانين
            الخصوصية المعمول بها وأن بياناتك محمية بضمانات كافية.
          </li>
        </ul>

        <strong>9. التغييرات على سياسة الخصوصية هذه</strong>
        <ul style="line-height: 20px">
          <li>
            قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر لتعكس التغييرات في
            ممارساتنا أو المتطلبات القانونية ذات الصلة. سنخبرك بأي تغييرات مهمة
            عن طريق نشر إشعار على بوابتنا أو إرسال تنبيه عبر الرسائل القصيرة.
            يشكل استمرارك في استخدام الخدمة بعد هذه التغييرات قبولا للسياسة
            المعدلة.
          </li>
        </ul>

        <strong>10. اتصل بنا </strong>
        <ul style="line-height: 20px">
          <li>
            Iإذا كانت لديك أي أسئلة أو مخاوف أو ملاحظات بخصوص سياسة الخصوصية هذه
            أو ممارسات معالجة البيانات الخاصة بنا، فيرجى الاتصال بنا على
            support@mt2morrow.com.
          </li>
          <li>
            باستخدام خدمتنا، فإنك تقر بأنك قد قرأت وفهمت سياسة الخصوصية هذه
            وتوافق على جمع معلوماتك الشخصية واستخدامها والإفصاح عنها كما هو موضح
            هنا.
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',

  data () {
    return {
      lang: localStorage.getItem('lang') ?? 'ar'
    }
  },
  methods: {
    showKurdish () {
      const countryCode = localStorage.getItem('countryCode')
      return countryCode
    }
  },

  mounted () {
    if (this.showKurdish() === '964') {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.subscribe_layout {
  background-image: url('../../assets/images/subscribe/subscribe_desk_bg.png');
  min-height: 100vh;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    background-image: url('../../assets/images/subscribe/bg_mobile.png');
  }
}

.content-container {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 30px;
  max-width: 600px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.terms-content {
  max-height: 600px;
  overflow-y: scroll;
  font-size: 14px;
  color: #212529;
  padding: 0 0 20px 0;
}

.go-back-btn {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 5px;
  cursor: pointer;
  text-decoration: underline;
}
</style>
